<template lang="pug">
.new-campaign-flow-url-input.text-center.mt-152
  om-heading.mb-20(h3) {{ $t(`optimization.${type}.start-phase.heading`) }}
  om-heading.mb-20(h1) {{ $t(`optimization.${type}.start-phase.subHeading`) }}
  .row.justify-content-center
    .col-9
      om-auto-complete#new-campaign-flow-url-input-input(
        ref="input"
        v-model="url"
        :disabled="loading"
        :placeholder="$t('optimization.sab.start-phase.inputPlaceholder')"
        :options="domainStrings"
        :prefixes="['https://', 'http://']"
        @enter="next"
      )
    .col-auto
      om-button(primary :loading="loading" :disabled="disabledNext || loading" @click="next") {{ $t('next') }}
</template>

<script>
  import { validateUrl } from '@/util';
  import { mapGetters } from 'vuex';

  export default {
    props: {
      initUrl: { type: String, default: null },
      loading: { type: Boolean, default: false },
      disabledNext: { type: Boolean, default: false },
    },
    data: () => ({ url: null, isValid: false }),
    computed: {
      ...mapGetters(['domains']),
      type() {
        return this.$route.query?.type ?? 'sab';
      },
      domainStrings() {
        return this.domains.map(({ domain }) => domain);
      },
    },
    mounted() {
      this.$nextTick(() => {
        if (this.initUrl) {
          this.url = this.initUrl;
        }
      });
    },
    methods: {
      next() {
        let url = this.url?.trim();
        if (!url) {
          this.$notify({
            type: 'error',
            text: this.$t(`optimization.sab.start-phase.validations.required`),
          });
          return;
        }

        if (url.toLowerCase().includes('localhost')) {
          this.$notify({
            type: 'error',
            text: this.$t(`optimization.sab.start-phase.validations.localhost`),
          });
          return;
        }

        if (!/^(https?)/.test(url)) {
          url = `https://${url}`;
        }

        if (validateUrl(url)) {
          const { href } = new URL(url);
          this.$emit('next', href);
        } else {
          this.$notify({
            type: 'error',
            text: this.$t(`optimization.sab.start-phase.validations.invalid`),
          });
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  .new-campaign-flow-url-input
    &.mt-152
      margin-top: 9.5rem
    .mb-20
      margin-bottom: 1.5rem
    ::v-deep .input-wrapper
      .error-domain
        text-align: left
</style>
